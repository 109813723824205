import axios from "axios";
import store from "@/store";
import router from "@/router";

const api = axios.create({
  baseURL: store.getters.apiURL,
  timeout: 16000,
});

// request
export async function request(data) {
  return await new Promise((resolve, reject) => {
    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) formData.append(key, data[key]);
      });

      api
        .post("", formData, { headers: { secret: store.getters.secret } })
        .then((res) => {
          if (!res.data.status || res.data.status !== "OK")
            throw new Error(res.data.message);

          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => responseFailed(err)
);

const responseFailed = (err) => {
  // server error or unauthorized
  if (
    ((err == "Error: Network Error" || err.response.status >= 500) &&
      store.getters.token) ||
    [401, 403].includes(err.response.status)
  ) {
    store.dispatch("setSignOut");
    router.push({ path: "/" }).catch(() => {
      /* ignore */
    });
  }

  return Promise.reject(err);
};

export default api;
