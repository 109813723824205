<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application v-else-if="$store.getters.authenticated">
      <router-view />
    </Application>

    <v-main v-else>
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "./services";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen";
import SnackBar from "./components/app/SnackBar";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  created() {
    this.startSession();
  },

  methods: {
    ...mapActions(["setToken", "setSignOut"]),

    // checks if the token is still valid for a session
    async startSession() {
      const token = this.$store.getters.token;

      if (token) {
        this.setToken(token);

        try {
          const payload = {
            method: "checkAdminToken",
          };

          await request(payload);
        } catch (err) {
          this.logoff();
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    logoff() {
      this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style src="./styles/style.scss" lang="scss" />
