import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token", "authorized"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    apiURL: "https://api.earthguard.online/",
    appURL: "https://sant.earthguard.online/",
    secret: "bQmusimfgitiiXtqGNJsusimfgitXtq",
    token: null,
  },

  getters: {
    apiURL: ({ apiURL }) => apiURL,
    appURL: ({ appURL }) => appURL,
    secret: ({ secret }) => secret,
    authenticated: ({ token }) => !!token,
    token: ({ token }) => token,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);

      // set header authotization
      api.defaults.headers.common["AdminAuthorization"] = payload;
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
