<template>
  <div class="d-flex flex-column">
    <div
      v-if="_pictures.length"
      class="d-flex justify-center align-center flex-wrap mb-4"
    >
      <v-sheet
        v-for="(item, i) in _pictures"
        :key="i"
        class="ma-2"
        color="white"
        style="padding: 1px"
      >
        <v-avatar
          class="pointer"
          color="accent"
          :size="_pictures.length > 3 ? 64 : 96"
          tile
          @click="onSelectImage()"
        >
          <v-img :src="item" />
        </v-avatar>
      </v-sheet>

      <v-btn
        v-if="files.length"
        class="accent rounded-lg ma-2"
        large
        icon
        @click="reset()"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>

      <v-btn
        v-else
        class="accent rounded-lg ma-2"
        large
        icon
        @click="onSelectImage()"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </div>

    <div v-else class="d-flex justify-center align-center mb-4">
      <v-avatar
        class="pointer"
        color="accent"
        size="96"
        tile
        @click="onSelectImage()"
      >
        <v-icon color="subtext" x-large> mdi-image-plus-outline </v-icon>
      </v-avatar>
    </div>

    <span
      v-if="!valid && !files.length"
      class="error--text text-caption text-center"
    >
      Campo Obrigatório
    </span>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      multiple
      @change="onFileChanged($event)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      files: [],
      preview: [],
    };
  },

  props: {
    current: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: true,
    },

    accept: {
      type: String,
      default: "image/jpeg,image/jpg,image/png",
    },

    allowVideo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _pictures() {
      if (this.files.length) return this.preview;
      if (this.current.length) return this.current;

      return [];
    },

    _accept() {
      if (this.allowVideo)
        return this.accept + ",video/mp4,video/webm,video/ogg";

      return this.accept;
    },
  },

  methods: {
    onSelectImage() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const files = [...e.target.files];

      this.files = files;
      this.preview = files.map((el) => URL.createObjectURL(el));

      this.$emit("input", this.files);
    },

    reset() {
      this.files = [];
      this.preview = [];
      this.valid = true;

      this.$emit("input", []);
    },

    validate() {
      this.valid = this.files.length || this.current.lenght;
      return this.valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
