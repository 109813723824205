import store from "../store";

export default async (to, from, next) => {
  //settings
  document.title = to.name + " - Admin Earth Guard";
  if (to.name != from.name) window.scrollTo(0, 0);

  // handle auth state
  const requiredAuth = to.meta.requiredAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const authenticated = store.getters.token ? true : false;

  // if the user has not authenticated and tries access private page
  if (requiredAuth && !authenticated) return next("/auth");

  // if the user has authenticated and tries access prevent authenticated page
  if (preventAuth && authenticated) return next("/");

  // else... next
  next();
};
