<template>
  <div class="d-flex flex-column">
    <v-card
      class="mb-2 card-border green-background"
      @click="onButtonClick()"
      flat
    >
      <div class="d-flex align-center pa-4">
        <v-avatar class="mr-4" color="white">
          <v-icon v-if="!preview" class="text">mdi-image-plus-outline</v-icon>
          <v-img v-else :src="preview" />
        </v-avatar>

        <div class="d-flex flex-column">
          <span class="text-body-2 font-weight-bold green-text">
            {{ file ? file.name : title }}
          </span>

          <span v-if="subtitle || file" class="text-caption green-text">
            {{ file || preview ? "Toque para alterar" : subtitle }}
          </span>
        </div>
      </div>
    </v-card>

    <span v-if="!valid && !file" class="error--text text-caption">
      Campo Obrigatório
    </span>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged($event)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      file: null,
      preview: "",
    };
  },

  props: {
    current: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "Selecione o arquivo pelo seu dispositivo",
    },

    required: {
      type: Boolean,
      default: true,
    },

    accept: {
      type: String,
      default: "image/*",
    },
  },

  methods: {
    onButtonClick() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];

      this.file = file;
      this.preview = URL.createObjectURL(file);

      this.$emit("input", file);
    },

    reset() {
      this.file = null;
      this.preview = "";
      this.valid = true;

      this.$emit("input", null);
    },

    validate() {
      this.valid = !!this.file || !!this.current;
      return this.valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.card-border {
  border: 2px dashed #99c183;
}
</style>
