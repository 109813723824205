<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">
      <span class="work-sans">{{ label }}</span>
    </div>

    <v-autocomplete
      v-model="value"
      v-on="$listeners"
      class="rounded-lg"
      color="overtext"
      background-color="surface"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      :outlined="outlined"
      :readonly="readonly"
      :disabled="disabled"
      solo
      flat
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
    };
  },

  props: {
    currentValue: null,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    items: {
      type: Array,
      default: () => [],
    },

    customRules: {
      type: Array,
      default: () => [],
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    itemText: {
      type: String,
      default: "text",
    },

    itemValue: {
      type: String,
      default: "value",
    },
  },

  beforeMount() {
    if (!!this.currentValue) this.value = this.currentValue;
  },

  computed: {
    rules() {
      return this.customRules.length <= 0
        ? [(v) => !!v || "Campo Obrigatório"]
        : this.customRules;
    },
  },

  methods: {
    handleInput(event) {
      this.value = event;
      this.$emit("input", event);
    },
  },
};
</script>

<style></style>
