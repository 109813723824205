import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import LoaderHover from "./components/loaders/LoaderHover";
import LoaderView from "./components/loaders/LoaderView";
import LoaderContent from "./components/loaders/LoaderContent";

import CustomInput from "./components/form/CustomInput";
import CustomMaskInput from "./components/form/CustomMaskInput";
import CustomMoneyInput from "./components/form/CustomMoneyInput";
import CustomFileInput from "./components/form/CustomFileInput";
import CustomSelect from "./components/form/CustomSelect";
import CustomTextArea from "./components/form/CustomTextArea";
import CustomDatePick from "./components/form/CustomDatePick";
import CustomAutoComplete from "./components/form/CustomAutoComplete";
import AvatarPick from "./components/form/AvatarPick";
import MultipleImagePick from "./components/form/MultipleImagePick";
import CustomBttn from "./components/form/CustomBttn";
import CardFileInput from "./components/form/CardFileInput";

Vue.component("loader-hover", LoaderHover);
Vue.component("loader-view", LoaderView);
Vue.component("loader-content", LoaderContent);

Vue.component("custom-input", CustomInput);
Vue.component("custom-mask-input", CustomMaskInput);
Vue.component("custom-money-input", CustomMoneyInput);
Vue.component("custom-file-input", CustomFileInput);
Vue.component("custom-select", CustomSelect);
Vue.component("custom-text-area", CustomTextArea);
Vue.component("custom-date-pick", CustomDatePick);
Vue.component("custom-auto-complete", CustomAutoComplete);
Vue.component("avatar-pick", AvatarPick);
Vue.component("multiple-image-pick", MultipleImagePick);
Vue.component("custom-bttn", CustomBttn);
Vue.component("card-file-input", CardFileInput);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
