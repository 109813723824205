<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-center align-center mb-2">
      <v-sheet color="white" style="padding: 1px">
        <v-avatar
          class="pointer"
          color="accent"
          size="128"
          tile
          @click="onButtonClick()"
        >
          <v-img v-if="!!file || !!current" :src="preview || current" />

          <v-icon v-else color="subtext" x-large>
            mdi-image-plus-outline</v-icon
          >

          <v-btn
            v-if="!!file"
            style="top: 3px; right: 3px"
            absolute
            top
            right
            small
            icon
            @click.stop="reset()"
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-avatar>
      </v-sheet>
    </div>

    <span v-if="!valid && !file" class="error--text text-caption text-center">
      Campo Obrigatório
    </span>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged($event)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      file: null,
      preview: "",
    };
  },

  props: {
    current: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: true,
    },

    accept: {
      type: String,
      default: "image/*",
    },
  },

  methods: {
    onButtonClick() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];

      this.file = file;
      this.preview = URL.createObjectURL(file);

      this.$emit("input", file);
    },

    reset() {
      this.file = null;
      this.preview = "";
      this.valid = true;

      this.$emit("input", null);
    },

    validate() {
      this.valid = !!this.file || !!this.current;
      return this.valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
