<template>
  <v-btn
    v-on="$listeners"
    class="text-none"
    :color="color"
    :large="!small"
    :block="block"
    :disabled="disabled"
    :rounded="rounded"
    :plain="plain"
    depressed
  >
    <span class="work-sans">
      {{ label }}
    </span>
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "primary",
    },

    small: {
      type: Boolean,
      default: false,
    },

    block: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    plain: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
