<template>
  <v-overlay style="background-color: #000000bf; z-index: 99999">
    <HollowDotsSpinner  :animation-duration="2000" :size="64" color="#48AB6F" />
  </v-overlay>
</template>

<script>
import { HollowDotsSpinner  } from "epic-spinners";

export default {
  components: {
    HollowDotsSpinner ,
  },
};
</script>

<style></style>
