<template>
  <div class="d-flex flex-column fill-height">
    <v-app-bar class="pl-2" color="background" flat app>
      <v-btn class="mr-4 drawer-bttn" small icon @click="handleDrawer()">
        <v-icon size="32">
          {{ mini && !drawer ? "mdi-menu" : "mdi-menu-open" }}
        </v-icon>
      </v-btn>

      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="text-h6 font-weight-regular"
      >
        <span class="work-sans"> Boas vindas </span>
      </div>

      <v-spacer />

      <div class="d-flex align-center">
        <v-btn small icon @click="logoff()">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      color="background"
      :mini-variant="mini"
      mini-variant-width="64"
      app
    >
      <div class="d-flex fill-height">
        <vue-custom-scrollbar
          class="scroll-area d-flex flex-column px-2"
          :settings="scrollSettings"
        >
          <!-- logo -->
          <div class="d-flex justify-center py-8">
            <v-img
              :src="
                require(mini ? '@/assets/logo.svg' : '@/assets/logotype.svg')
              "
              max-width="160"
              max-height="48"
              contain
            />
          </div>

          <!-- routes -->
          <v-list class="transparent pa-0 mb-6" dense nav>
            <router-link
              v-for="(item, i) in routes"
              v-slot="{ href, navigate, isActive }"
              :to="item.path"
              :key="i"
              custom
            >
              <v-list-item
                :href="href"
                :disabled="item.disabled"
                :class="`${!item.disabled || 'disabled-item'} mb-2`"
                @click="navigate"
              >
                <v-list-item-icon :class="{ 'mr-4': !mini }">
                  <v-icon :color="isActive ? 'primary' : 'overtext'">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  :class="
                    isActive
                      ? 'primary--text font-weight-bold'
                      : 'overtext--text'
                  "
                >
                  <span class="text-caption">
                    {{ item.label }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
        </vue-custom-scrollbar>
      </div>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar/src/vue-scrollbar.vue";

export default {
  data() {
    return {
      drawer: true,
      mini: false,
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },

  components: {
    vueCustomScrollbar,
  },

  computed: {
    routes() {
      return [
        {
          label: "Início",
          path: "/home",
          icon: "mdi-view-dashboard",
          disabled: false,
        },
        {
          label: "Feed",
          path: "/feed",
          icon: "mdi-rss",
          disabled: false,
        },
        {
          label: "Gerenciar Produtos",
          path: "/products",
          icon: "mdi-chart-arc",
          disabled: false,
        },
        {
          label: "Produtos Contratados",
          path: "/hired-products",
          icon: "mdi-chart-donut-variant",
          disabled: false,
        },
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-multiple",
          disabled: false,
        },
        {
          label: "Depósitos",
          path: "/deposits",
          icon: "mdi-arrow-down-bold-box-outline",
          disabled: false,
        },
        {
          label: "Faturas",
          path: "/installments",
          icon: "mdi-arrow-down-bold-circle-outline",
          disabled: false,
        },
        {
          label: "Saques",
          path: "/withdrawals",
          icon: "mdi-arrow-up-bold-box-outline",
          disabled: false,
        },
        {
          label: "Comissões",
          path: "/commissions",
          icon: "mdi-account-cash",
          disabled: false,
        },
        {
          label: "Relatórios",
          path: "/reports",
          icon: "mdi-chart-box-outline",
          disabled: false,
        },
        {
          label: "Logs do sistema",
          path: "/system-logs",
          icon: "mdi-file-document-outline",
          disabled: false,
        },
        {
          label: "Definições",
          path: "/settings",
          icon: "mdi-tools",
          disabled: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions(["setSignOut"]),

    handleDrawer() {
      if (this.drawer && !this.mini) {
        this.mini = true;
        return;
      }

      if (this.drawer && this.mini) {
        this.drawer = false;

        setTimeout(() => (this.mini = false), 1000);
        return;
      }

      this.drawer = true;
    },

    logoff() {
      this.setSignOut();
      this.$router.push({ path: "/auth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  height: 100%;
  width: 100%;
}

.disabled-item {
  opacity: 0.5;
}
</style>
