<template>
  <v-main class="background">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="6" sm="5" md="4" lg="3">
          <v-img :src="require('../assets/logotype.svg')" contain />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
