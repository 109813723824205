<template>
  <v-snackbar
    class="d-flex justify-center"
    v-model="snackbar"
    style="z-index: 99999;"
    :color="colors[type]"
    :timeout="timeout"
    absolute
    top
  >
    <span class="work-sans">{{ message }}</span>

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon @click="snackbar = false">
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      colors: ["success", "error", "info"],
      snackbar: false,
      message: "",
      type: 0,
      timeout: 3000,
    };
  },

  mounted() {
    this.$root.$on("display-alert", (data) => {
      this.message = data.message;
      this.type = data.type;
      this.timeout = data.fixed ? -1 : 3000;
      this.snackbar = true;
    });
  },
};
</script>

<style lang="scss">

</style>
